<template>
    <div class="box">
        <div class="title">志愿者招募</div>
        <div class="time">
            <span>{{ data.createTime || '' }}</span>
            <!-- <span>访问量：{{ data.readCount || 0 }}</span> -->
        </div>
        <div v-html="data.content ? data.content.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {
                createTime: '',
                readCount: 0,
            },
        };
    },
    created() {
        this.$hao.getVolunteerRecruitList({ libraryId: localStorage.getItem('id') }).then((res) => {
            console.log(res);
            if (res.rows.length) {
                this.data = res.rows[0];
            }
        });
    },
    methods: {},
};
</script>

<style scoped lang="scss">
.title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 20px 0;
    font-weight: 700;
    font-size: 32px;
}
.time {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 40px 0;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.5);
}
</style>
